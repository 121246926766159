var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.website.styles),function(props,element){return _c('ZyroCollapse',{key:element,staticClass:"element",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('h2',{staticClass:"element__name"},[_vm._v(" "+_vm._s(element)+" ")])]},proxy:true}],null,true)},[_c('div',_vm._l((props),function(value,property){return _c('div',{key:(element + "-" + property),staticClass:"element__property"},[(element === 'colors' || /color|background/.test(property))?_c('ZyroColorPickerPlain',{attrs:{"value":value},on:{"close":function($event){return _vm.setStyleProperty({
						element: element,
						property: property,
						value: $event
					})}}}):_vm._e(),_c('ZyroFieldText',{attrs:{"value":value,"label":(property + " [ var(--" + element + "-" + property + ") ]")},on:{"input":function($event){return _vm.setStyleProperty({
						element: element,
						property: property,
						value: $event.target.value
					})}}})],1)}),0)])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }