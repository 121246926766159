<template>
	<div>
		<ZyroCollapse
			v-for="(props, element) in website.styles"
			:key="element"
			class="element"
		>
			<template #trigger>
				<h2 class="element__name">
					{{ element }}
				</h2>
			</template>
			<div>
				<div
					v-for="(value, property) in props"
					:key="`${element}-${property}`"
					class="element__property"
				>
					<ZyroColorPickerPlain
						v-if="element === 'colors' || /color|background/.test(property)"
						:value="value"
						@close="setStyleProperty({
							element,
							property,
							value: $event
						})"
					/>
					<ZyroFieldText
						:value="value"
						:label="`${property} [ var(--${element}-${property}) ]`"
						@input="setStyleProperty({
							element,
							property,
							value: $event.target.value
						})"
					/>
				</div>
			</div>
		</ZyroCollapse>
	</div>
</template>
<script>
import {
	mapState,
	mapMutations,
} from 'vuex';

export default {
	computed: { ...mapState(['website']) },
	methods: { ...mapMutations(['setStyleProperty']) },
};
</script>

<style lang="scss" scoped>
.element {
	border-top: 1px solid $grey-200;
}
</style>
